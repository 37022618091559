import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Logout from '@mui/icons-material/Logout';
import HeaderCart from '../Components/HeaderCart';
function Navigation({ handleSignOut, userInfo }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = () => {
    setAnchorEl(null); // Close the menu after a menu item is clicked (optional)
  };
  const navbarStyle = {
    backgroundColor: "#FAF9F6",
    boxShadow: "0px 0px 20px gray",
    width: "100%",
    height: "108px",
    transition: "top 0.5s ease-in-out",
    zIndex: "2",
  };
  return (
    <nav style={navbarStyle} className="bg-gray-100 p-4 flex items-center justify-between">
      <div className="flex items-center">
        <Link to="/">
          <h1 className="text-3xl md:text-4xl font-bold"><span className="text-[#3d52a0]">Tap</span><span className="text-[#7091e6]">mate</span><span className="text-[#8697c4]">.in</span></h1>
        </Link>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="md:hidden">
        <Tooltip title="Menu">
          <IconButton onClick={handleClick}>
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </div>

      {/* Menu Items */}
      <div className="md:flex md:items-center md:space-x-6 hidden md:block">
        <ul className="md:flex md:space-x-6">
          <li>
            <Link to="/qrform" className="text-gray-600 text-[20px] hover:text-gray-800">
              QR Code Generator
            </Link>
          </li>
          <li>
            <Link to="/" className="text-gray-600 text-[20px] hover:text-gray-800">
              Home
            </Link>
          </li>
          <li>
            <Link to="/product" className="text-gray-600 text-[20px] hover:text-gray-800">
              Product
            </Link>
          </li>
          <li>
            <Link
              to="/Sustainability"
              className="text-gray-600 text-[20px] hover:text-gray-800"
            >
              Sustainability
            </Link>
          </li>
          <li>
            <Link to="/" className="text-gray-600 text-[20px] hover:text-gray-800">
              Support
            </Link>
          </li>
          <li>
            <Link to="/cart" className="text-gray-600 text-[20px] hover:text-gray-800">
            <HeaderCart/>
            </Link>
          </li>
        </ul>

        {/* Account Settings */}
        <div className="flex items-center">
          <Tooltip className='' title="Account settings">
            <IconButton onClick={handleClick}>
              <AccountCircleOutlinedIcon className='' />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {/* Mobile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: '40px', // Adjust as needed
            '& .MuiMenuItem-root': {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          },
        }}
      >
        <MenuItem onClick={handleMenuClick}>
          <Avatar /> {userInfo?.data?.username}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleMenuClick}>
          <ListItemIcon>
            <BookmarkBorderOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Link to="/order" className="text-gray-600 hover:text-gray-800">
            My Orders
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClick}>
          <ListItemIcon>
            <DashboardOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Link to="/dashboard" className="text-gray-600 hover:text-gray-800">
            Dashboard
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClick}>
          <ListItemIcon>
            <DashboardOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Link to="/cart" className="text-gray-600 hover:text-gray-800">
          <HeaderCart/>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>

      </Menu>
    </nav>
  );
}

export default Navigation;
