import React from 'react'
import Navbar from "../Components/Navbar"
import classicimg from "../Images/background1.png"
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

function Sustainability() {

    const linearGradientStyle = {
        // background: 'linear-gradient(90deg, #022D24 11.02%, #146C60 88.41%)',
        // WebkitBackgroundClip: 'text',
        // color: 'transparent',
    };

    const backgroundTexture = {
        backgroundImage: `url(${classicimg})`,
        backgroundSize: 'cover', // Adjust the background size if needed
        with: '140px',
        height: '150vh', // Set a specific height
        backgroundAttachment: ' fixed'
    };

    const isUserLoggedIn = localStorage.getItem("tpt_token") != null ;
    return (
        <>
            <Navbar />
            <div
                style={backgroundTexture}
            >
                <div className='flex flex-col items-center gap-24 pt-48 xl:pt-64 '>
                    <div className='flex flex-col items-center gap-12 '>
                        <div className='flex flex-col items-center gap-9 '>
                            <h3 style={linearGradientStyle} className='xl:text-[56px] text-5xl text-[#3d52a0] text-center w-[95%] md:w-full border-b'>Sustainability and Efficiency</h3>
                            <p className='text-[16px] font-sans font-normal  text-center text-[#3d52a0]'> Aligned with Future Vision</p>
                        </div>
                        <div className='text-center hidden md:block'>
                            <p className='text-[16px] text-[#8697c4]'>As a proponent for sustainability, Tapmate aligns its Business solution with India's initiatives towards </p>
                            <p className='text-[16px] text-[#8697c4]'>environmental conservation, digital innovation, and paperless practices. Tapmate visiting cards play </p>
                            <p className='text-[16px] text-[#8697c4]'>a pivotal role in minimizing paper waste, contributing directly to India's journey towards a greener</p>
                            <p className='text-[16px] text-[#8697c4]'>and more digitally empowered future.</p>
                        </div>
                        <div className=' text-center sm:hidden text-[#8697c4] p-2 text-[16px]'>
                            <p>
                            As a proponent for sustainability, Tapmate aligns its Business solution with India's initiatives towards
                            environmental conservation, digital innovation, and paperless practices. Tapmate visiting cards play 
                            a pivotal role in minimizing paper waste, contributing directly to India's journey towards a greener
                            and more digitally empowered future.
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-16'>
                        <div className=' leading-none text-center'>
                            <p className='text-[#84A7AC] text-[42px] xl:text-[74px]'>Buy Less,</p>
                            <p className='text-[#CAE7E5] text-[42px] xl:text-[74px]'>Choose Well,</p>
                            <p className='text-[#D79F6F] text-[42px] xl:text-[74px]'>Make it Last.</p>
                        </div>
                        <div className='mt-4'>
                            <button className='border border-[#3d52a0]  text-[#3d52a0] hover:bg-[#022D24] hover:text-white rounded-sm text-[20px]  px-8 py-2'>
                              
                                {isUserLoggedIn ? (
                                    <Link to={"/qrform"}>Get Your Card</Link>
                                    ) : (
                                    <Link to={"/login"}>Get Your Card</Link>
                                    )}
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Sustainability
