import React from 'react'
import dCard from "../Images/dCard.svg"
import img1 from "../Images/img1.svg"
import img2 from "../Images/img2.svg"
import img3 from "../Images/img3.svg"
import img4 from "../Images/img4.svg"
import { IoIosArrowRoundForward } from "react-icons/io";



function DeginationCard() {


    const imgmenu = {
        imageUrl1: img1,
        imageUrl2: img2,
        imageUrl3: img3,
        imageUrl4: img4,

    }


    return (
        <div className='w-full bg-[#FAF9F6] my-12 xl:my-32'>
            <div className=' max-w-screen-xl xl:py-16 py-4 xl:pl-10  m-auto xl:gap-16 flex flex-wrap justify-evenly items-center '>
                <div className=''>
                    <img src={dCard} alt="card" />
                </div>
                <div className=' xl:w-[50%] p-3 text-justify flex flex-col gap-5'>
                    <div className='hidden md:block'>
                        <p className=' font-[inter] font-medium  text-[#3d52a0]'> Tapmate goes beyond the mundane; it's more than just a card – it's a</p>
                        <p className=' font-[inter] font-medium  text-[#3d52a0]'>dedication to a sustainable tomorrow. Driven by state-of-the-art encrypted </p>
                        <p className=' font-[inter] font-medium  text-[#3d52a0]'>NFC technology, trust that your data is secure, and interactions,</p>
                        <p className=' font-[inter] font-medium  text-[#3d52a0]'>are as dependable as sunrise. Personalize your digital </p>
                        <p className=' font-[inter] font-medium  text-[#3d52a0]'>profiles, liberating them from the limitations of conventional paper,</p>
                        <p className=' font-[inter] font-medium  text-[#3d52a0]'> enriching their functionality in unprecedented ways.</p>
                        <div className=" leading-none mt-3">
                        </div>
                        <p className='text-[#8697c4] text-[20px]'>Complete Name</p>
                        <p className='text-[16px] text-[#8697c4]'>Occupational Title</p>
                    </div>
                    <div className=' md:hidden'>
                        <p className='text-[16px] text-[#3d52a0]'>
                        Tapmate goes beyond the mundane; it's more than just a card – it's a dedication to a sustainable tomorrow. Driven by state-of-the-art 
                        encrypted NFC technology, trust that your data is secure, and interactions are as dependable as sunrise. Personalize your digital profiles, liberating them from the limitations of conventional paper, enriching their functionality in unprecedented ways.

                           </p>
                        <p className='text-[#3d52a0] md:hidden font-normal mt-4 text-[20px]'>Complete Name</p>
                        <p className='text-[16px] md:hidden text-[#3d52a0]'>Occupational Title</p>
                    </div>

                    <div className='flex flex-wrap mt-4 gap-10 items-center '>
                        {Object.keys(imgmenu).map((key, index) => (
                            <img key={index} src={imgmenu[key]} alt={`Image ${index + 1}`} />
                        ))}
                        <span className='text-[20px] text-[#3d52a0]'>Meet all card holders</span><IoIosArrowRoundForward />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DeginationCard
