import React, { useState } from "react";
import MologLogo from "../Images/molog logo 1.svg"
import phone from "../Images/phone.png";
import back from "../Images/Group2.png";
import { Link } from "react-router-dom";
import img from "../Images/Tap & Tag white logo 2.png";
import img2 from "../Images/Photo by Edmond Dantès.png";
import { IoIosArrowDown } from "react-icons/io";
import MobilePreview from "../Components/MobilePreview";
import MobileExample from "./MobileExample";
import "../Css/home.css"

function Main() {
  const styleborder = {
    boxShadow:
      "-6px -6px 6px 0px #F5F2F0, 4px 4px 4px 0px #022D2440, 3px 3px 6px 3px #F5F2F0",
  };

  const sectionStyle = {
    // backgroundImage:` url(${back})`,
    // backgroundSize: "cover", // Adjust the background size if needed
    backgroundColor: '#3d52a0'
  };

  function toggleOverlay() {
    var overlay = document.getElementById("overlay");
    overlay.classList.toggle("opacity-0");
    overlay.classList.toggle("opacity-100");
    overlay.classList.toggle("pointer-events-none");
    overlay.classList.toggle("pointer-events-auto");
  }

  const [isHidden, setIsHidden] = useState(false);

  const handleanimation = () => {
    setIsHidden(!isHidden);
  };

  const gradientTextStyle = {
    background: 'linear-gradient(180deg, #1E1E1E -61.11%, #079376 177.78%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent'
};
const isUserLoggedIn = localStorage.getItem('tpt_token') != null ;

  return (
    <>
      <div className="flex flex-wrap xl:max-w-screen-xl xl:pt-52 pt-24 xl:px-8 p-5 gap-16 xl:gap-0 mx-auto items-center justify-between">
        <div className="flex flex-col gap-10">
          <div className=" ">
            <p className="md:text-[75px] leading-10 text-3xl text-[#adbbda] text-[#8697c4]">
            Unleashing Your 
            </p>
            <p className="md:text-[75px]  leading-tight text-3xl   tracking-wide   text-[#8697c4]">
            Networking Potential
            </p>
            <span className=" xl:text-[16px] font-sans leading-loose font-light text-sm text-[#717171]">
            Say goodbye to traditional business cards – tap to share your information in an instant.
            </span>
          </div>
          <div>
          <button className="btn-hover  bg-[#3d52a0] ">
              {isUserLoggedIn ?(
              <Link to={"/qrform"}>Get Your Card</Link>
              ):(
                <Link to={"/login"}>Get Your Card</Link>
              )}
            </button>
          </div>
        </div>
        <div className="">
          <div
            style={styleborder}
            className="xl:w-[424px] w-[300px] h-[150px] flex flex-col justify-between xl:h-[230px] p-3 xl:p-10 bg-[FAF9F4] rounded-xl "
          >
            <div className=" ">
              <p className="xl:text-[19px] text-[#3d52a0]">FULL NAME</p>
            </div>
            <div className="flex flex-col gap-1 md:leading-4 mt-1">
              <span className="md:text-[11px]  text-[9px] text-[#3d52a0] font-light  font-[Poppins]">
                Employee’s Designation
              </span>
              <span className="md:text-[11px] text-[9px] text-[#3d52a0] font-light font-[Poppins]">
                +91 9999***999
              </span>
            </div>

            <div className="flex  w-full   items-end justify-end ">
              {/* <img
                src={MologLogo}
                class=" xl:h-[91px] h-14 xl:mt-10 mt-4 "
                alt="Molog Logo"
              /> */}
              <h1 className="text-[2rem] text-[#3d52a0]">Tapmate.in</h1>
            </div>
          </div>
        </div>
      </div>
      <section
        style={sectionStyle}
        className="xl:mt-32 mt-10 h-[670px] flex items-center justify-center "
      >
        {/* <img src={phone} alt="" /> */}
        <img src="/image/nfc.gif" className=" rounded-[1rem] object-cover w-[350px] h-[600px]"  alt="" />
      </section>
    </>
  );
}

export default Main;